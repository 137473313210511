import { AfterViewInit, Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ProductResource } from '@components/product/product.resource';
import { AbstractResource } from '@resources/abstract.resource';
import { AbstractComponent } from '@components/generic/abstract.component';
import { AuthService } from '@services/auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SnackbarService } from '@components/snackbar';
import { INPUT_NUMBER_PATTERN_DEC, INPUT_NUMBER_PATTERN_NODEC } from '@constants/form';
import { IProduct } from '@components/product/interfaces';
import { IMarketplace, IMarketplacesByCountry } from '@interfaces/marketplace.interface';
import { takeUntil } from 'rxjs/operators';
import { DATE_FULL_FORMAT, DATE_SHORT_FORMAT } from '@constants/date';
import moment = require('moment');
import { ProductMarketplaceStatusResource } from '@resources/product-marketplace-status.resource';
import { Observable } from 'rxjs/Observable';
import { SessionHelper } from '@helpers/session.helper';
import { MarketplaceHelper } from '@helpers';
import { IMarketplace as IProductMarketplace } from '@components/product/interfaces';
import {ProductListComponent} from '@components';

@Component({
  selector: 'app-product-list-bulk-edition',
  template: require('./product-list-bulk-edition.component.html'),
  styles: [require('./product-list-bulk-edition.component.scss')],
  providers: [{provide: AbstractResource, useClass: ProductResource}],
  encapsulation: ViewEncapsulation.None,
})
export class ProductListBulkEditionComponent extends AbstractComponent implements OnInit, AfterViewInit {

  private form: FormGroup;
  protected marketplaces: IMarketplacesByCountry;
  readonly productStatuses: any[];
  protected statusList$: Observable<Object>;
  public modalOpened: boolean = false;
  public popinElement: any;

  public height: number;

  @Input() public productList: IProduct[];
  @Input() public parent: ProductListComponent;
  @Input() private selectedProductListCount: number;
  @Input() private marketplacesNotToSelect: string[];
  @Input() private countriesNotDisplay: string[];

  @Output() public onRemoveSkuFromBulkEdition: EventEmitter<any> = new EventEmitter();

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    private formBuilder: FormBuilder,
    @Inject('DialogService') private dialog: any,
    private snackbar: SnackbarService,
    private productMarketplaceStatusResource: ProductMarketplaceStatusResource,
    private elementRef: ElementRef,
  ) {
    super($translate, authService, resource, state);

    this.productStatuses = [
      { value: 'ready_for_sale', text: this.$translate.instant('PAGE.PRODUCT.STATUSES.STATUS_READY_FOR_SALE') },
      { value: 'end_of_life', text: this.$translate.instant('PAGE.PRODUCT.STATUSES.STATUS_END_OF_LIFE') },
    ];
  }

  public ngOnInit(): void {
    this.statusList$ = this.productMarketplaceStatusResource.getManyWithLabel();
    this.marketplaces = this.fetchMarketplaces();

    this.buildForm();
  }

  public fetchMarketplaces(): IMarketplacesByCountry {
    const marketplacesByCountry: IMarketplacesByCountry = MarketplaceHelper.getMarketplacesByCountry();

    const result: IMarketplacesByCountry = {};

    Object.keys(marketplacesByCountry).filter((countryCode: string) => !this.countriesNotDisplay.includes(countryCode)).map((countryCode: string) => {
      result[countryCode] = marketplacesByCountry[countryCode].map((marketplace: IMarketplace) => {
        marketplace.selected =  MarketplaceHelper.isFilteredOnProductListPage(marketplace.code, countryCode);

        return marketplace;
      });
    });

    return result;
  }

  public ngAfterViewInit(): void {
    this.height = this.elementRef.nativeElement.querySelector('.portlet').offsetHeight;
  }

  private buildForm(): void {
    this.form = this.formBuilder.group({
      stock: [null, Validators.pattern(INPUT_NUMBER_PATTERN_NODEC)],
      basePrice: [null, Validators.pattern(INPUT_NUMBER_PATTERN_DEC)],
      shippingPrice: [null, Validators.pattern(INPUT_NUMBER_PATTERN_DEC)],
      shippingDate: [null],
      specialPrice: [null, Validators.pattern(INPUT_NUMBER_PATTERN_DEC)],
      quantityForCut: [null],
      status: [null],
      productStatus: [null],
      reinitializeDate: [false],
      reinitializeOffers: [false],
    });
  }

  private cancel(): void {
    this.dialog.confirm(this.translate('DIALOG.TEXT.DONT_SAVE')).then(() => {
      this.resetSelection();
    });
  }

  private resetSelection(): void {
    this.form.reset();
    this.productList.forEach((product: IProduct) => product.selected = false);
    this.onRemoveSkuFromBulkEdition.emit();
    this.selectedProductListCount = 0;
  }

  private save(): void {
    let updated: boolean = false;

    Object.keys(this.marketplaces).map((countryCode: string) => {
      const marketplaces: IMarketplace[] = this.marketplaces[countryCode];

      marketplaces.map((marketplace: IMarketplace) => {
        if (marketplace.selected) {
          updated = true;
        }
      });
    });

    if (!updated) {
      this.dialog.alert(this.translate('PAGE.PRODUCT.LIST.TAB.ECOMMERCE.BULK_EDITOR.ALERTS'));

      return;
    }

    this.dialog.confirm(this.translate('PAGE.PRODUCT.CONFIRM.UPDATE'))
      .then(() => {
        (<ProductResource>this.resource).batchUpdateV2(this.prepareBody())
          .pipe(takeUntil(this.destroyed$))
          .subscribe(() => {
            this.snackbar.validate(this.translate('ALERTS.FORM.SAVED'));
            this.resetSelection();
            this.parent.loadItems(this.state.params);
          });
      });
  }

  private prepareBody(): any {
    const body: any = {};

    const selectedProducts = this.productList.filter(product => product.selected);

    selectedProducts.forEach((product: IProduct) => {
      Object.keys(this.marketplaces).map((countryCode: string) => {
        const marketplaces: IMarketplace[] = this.marketplaces[countryCode].filter((marketplace: IMarketplace) => marketplace.selected);

        if (marketplaces.length === 0) {
          return;
        }

        marketplaces.map((marketplace: IMarketplace) => {
          const productId = product.brotherProducts[countryCode];

          if (undefined === productId) {
            return;
          }

          if (undefined === body[productId]) {
            body[productId] = {productMarketplaces: {}};
          }

          const value = {
            stock: typeof this.form.get('stock').value === 'string' ? parseInt(this.form.get('stock').value, 10) : this.form.get('stock').value,
            basePrice: typeof this.form.get('basePrice').value === 'string' ?
              parseFloat(this.form.get('basePrice').value.replace(',', '.')) : this.form.get('basePrice').value,
            currentOffer: this.form.get('specialPrice').value && !this.form.get('reinitializeOffers').value ? {
              specialPrice: typeof this.form.get('specialPrice').value === 'string' ?
                parseFloat(this.form.get('specialPrice').value.replace(',', '.')) : this.form.get('specialPrice').value,
            } : null,
            shippingPrice: typeof this.form.get('shippingPrice').value === 'string' ?
              parseFloat(this.form.get('shippingPrice').value.replace(',', '.')) : this.form.get('shippingPrice').value,
            shippingDate: this.form.get('shippingDate').value && !this.form.get('reinitializeDate').value
              ? moment(this.form.get('shippingDate').value, DATE_SHORT_FORMAT).format(DATE_FULL_FORMAT)
              : null,
            status: this.form.get('status').value ? this.form.get('status').value.value : null,
            quantityForCut: typeof this.form.get('shippingPrice').value === 'string' ?
              parseInt(this.form.get('quantityForCut').value, 10)
              : this.form.get('quantityForCut').value
          };

          if (!this.form.get('specialPrice').value && !this.form.get('reinitializeOffers').value) {
            delete value.currentOffer;
          }

          if (!this.form.get('shippingDate').value && !this.form.get('reinitializeDate').value) {
            delete value.shippingDate;
          }

          if (
            undefined === product.brotherProductMarketplaces[marketplace.code]
            || undefined === product.brotherProductMarketplaces[marketplace.code][countryCode]
          ) {
            return;
          }

          const productMarketplace = product.brotherProductMarketplaces[marketplace.code][countryCode];

          if (this.isLockedOffer(productMarketplace)) {
            delete value.currentOffer;
          }

          body[productId]['productStatus'] = this.form.get('productStatus').value ? this.form.get('productStatus').value.value : null;
          body[productId].productMarketplaces[marketplace.code] = value;
        });
      });
    });

    return body;
  }

  private isOffer(productMarketplace: IProductMarketplace): boolean {
    return !!productMarketplace && !!productMarketplace.currentOffer;
  }

  private isLockedOffer(productMarketplace: IProductMarketplace): boolean {
    return this.isOffer(productMarketplace) && (!!productMarketplace.currentOffer.dateBegin
      || !!productMarketplace.currentOffer.dateEnd
      || productMarketplace.currentOffer.hasCommercialOperation);
  }

  private removeSku(id: number): void {
    this.productList.find((selectedProduct: IProduct) => selectedProduct.id === id).selected = false;
    this.onRemoveSkuFromBulkEdition.emit();
    this.selectedProductListCount--;
  }

  private exportCsv(): void {
    const body: any = {
      productIds: this.productList.filter((product: IProduct) => product.selected)
        .map((product: IProduct) => product.id),
      locale: SessionHelper.getLocale(),
    };

    (<ProductResource>this.resource).exportV2(body, { responseType: 'blob', dontUseModel: true })
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: any) => {
        const reader = new FileReader();

        reader.readAsDataURL(response);

        reader.addEventListener('loadend', () => {
          opener = window.document.createElement('a');
          opener.href = reader.result;
          opener.download = 'products_export_' + moment().format(DATE_FULL_FORMAT) + '.csv';

          document.body.appendChild(opener);
          opener.click();

          document.body.removeChild(opener);
        });
      });
  }

  public getMarketplacesKeys(): string[] {
    return Object.keys(this.marketplaces);
  }

  public hasSomeChecked(countryCode: string): boolean {
    return this.marketplaces[countryCode].some(marketplace => marketplace.selected);
  }

  public toggleCheck(countryCode: string): void {
    const controls: IMarketplace[] = this.marketplaces[countryCode];
    const checkedValue = !this.hasSomeChecked(countryCode);

    controls.forEach((control: IMarketplace) => {
      control.selected = checkedValue && !this.marketplacesNotToSelect.includes(control.code);
    });
  }

  public showModal(event: MouseEvent): void {
    event.stopPropagation();
    this.modalOpened = true;
    document.body.addEventListener('click', this.handleOutsideClick);
  }

  private handleOutsideClick = (event: MouseEvent) => {
    this.popinElement = document.getElementById('modal-bulk');

    if (null !== this.popinElement && !this.popinElement.contains(event.target)) {
      this.modalOpened = false;

      document.body.removeEventListener('click', this.handleOutsideClick);
    }
  }

  public getMarketplacesButtonLabel(): string {
    let label: string = this.$translate.instant('PAGE.ORDER.LIST.FILTER.MARKETPLACES.LABEL');
    let number: number = 0;

    Object.keys(this.marketplaces).map((countryCode: string) => {
      const marketplaces: IMarketplace[] = this.marketplaces[countryCode].filter((marketplace: IMarketplace) => marketplace.selected);
      number += marketplaces.length;
    });

    if (number > 0) {
      label += ' (' + number + ')';
    }

    return label;
  }

  public getMarketplacesButtonTitle(): string {
    let title: string = '';

    Object.keys(this.marketplaces).map((countryCode: string) => {
      const marketplaces: IMarketplace[] = this.marketplaces[countryCode].filter((marketplace: IMarketplace) => marketplace.selected);

      marketplaces.forEach((marketplace: IMarketplace) => {
        title += marketplace.commercialName + ' / ';
      });
    });

    return title;
  }
}
