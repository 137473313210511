import { AbstractComponent } from '@components/generic/abstract.component';
import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { AbstractResource } from '@resources/abstract.resource';
import { FormGroup } from '@angular/forms';
import { OrderResource } from '@resources';
import { takeUntil } from 'rxjs/operators';
import { SessionHelper } from '@helpers';
import { IMarketplace } from '@interfaces';
import {
  BUSINESS_OBJECT_ADDRESS,
  BUSINESS_OBJECT_ORDER,
  INDICATOR_ADDRESS_TYPE,
  INDICATOR_MARGIN_TYPE,
  INDICATOR_DUPLICATE_TYPE,
  INDICATOR_FRAUD_TYPE,
  INDICATOR_SUPPLY_OPTIMIZATION_TYPE,
  INDICATOR_ESTIMATE_TYPE
} from '@constants';
import { IndicatorComponent } from '@components/generic/indicator';
import { OrderManagerGeneralFormComponent } from './tab-general/order-manager-general-form.component';
import { ITabs } from '@components/generic/tabs/tabs.interface';
import { OrderDuplicateService } from '@services';
import { HttpHeaders } from '@angular/common/http';
import {OrderManagerRelationRessource} from '@components/order-manager/relation/order-manager-relation.ressource';
import {IRelation} from '@components/order-manager/relation/order-manager-relation.interface';
import {OrderManagerResource} from '@components/order-manager/order-manager.resource';
import {IHydraMember} from '@interfaces/hydra-resource.interface';
import {LitigationResource} from '@components/order-manager/resources/litigation.resource';

@Component({
  selector: 'app-order-manager-form',
  template: require('./order-manager-form.component.html'),
  styles: [require('./order-manager-form.component.scss')],
  providers: [
    { provide: AbstractResource, useClass: OrderResource },
    OrderManagerRelationRessource,
    LitigationResource
  ],
})
export class OrderManagerFormComponent extends AbstractComponent implements OnInit {

  public form: FormGroup;

  @Input() public order: any;
  @Input() public marketplace: IMarketplace;

  public BUSINESS_OBJECT_ADDRESS: string = BUSINESS_OBJECT_ADDRESS;
  public BUSINESS_OBJECT_ORDER: string = BUSINESS_OBJECT_ORDER;
  public INDICATOR_ADDRESS_TYPE: string = INDICATOR_ADDRESS_TYPE;
  public INDICATOR_MARGIN_TYPE: string = INDICATOR_MARGIN_TYPE;
  public INDICATOR_DUPLICATE_TYPE: string = INDICATOR_DUPLICATE_TYPE;
  public INDICATOR_FRAUD_TYPE: string = INDICATOR_FRAUD_TYPE;
  public INDICATOR_SUPPLY_OPTIMIZATION_TYPE: string = INDICATOR_SUPPLY_OPTIMIZATION_TYPE;
  public INDICATOR_ESTIMATE_TYPE: string = INDICATOR_ESTIMATE_TYPE;

  private shippingAddressId: number;
  public showBanner: boolean = false;
  public showBannerMessage: string = '';
  public orderEntityCommercialCurrency: string = '';
  public colors: any = [];

  @ViewChild(IndicatorComponent)
  private indicatorComponent: IndicatorComponent;

  @ViewChild(OrderManagerGeneralFormComponent)
  private orderManagerGeneralFormComponent: OrderManagerGeneralFormComponent;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    private orderDuplicateService: OrderDuplicateService,
    @Inject('StateService') state: ng.ui.IStateService,
    private relationRessource: OrderManagerRelationRessource,
    private orderManagerResource: OrderManagerResource,
    private litigationResource: LitigationResource
  ) {
    super($translate, authService, resource, state);
  }

  /**
   * @inheritDoc
   */
  ngOnInit(): void {
    this.fetch();
    this.getTransactions();
    this.getLitigations();
    this.getAssociated();
    this.getRelations();
  }

  public fetch(): void {
    if (!/^\+?(0|[1-9]\d*)$/.test(this.state.params.id)) {
      this.tryRedirect();
      return;
    }

    (<OrderResource>this.resource)
      .getOrderManagerOrder(this.state.params.id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (response: any) => {
          this.order = response;
          this.shippingAddressId = this.getAddressId(this.order.shippingAddress);
          this.marketplace = SessionHelper.getAllMarketplaces().find((item: IMarketplace) => item.code === this.order.marketplace);
          this.updateRefundLabel();
          this.updateTabCommentLabel();
          this.updateTabLitigationLabel();
          this.updateTabReverseLabel();
          this.updateAssociatedLabel();
          this.updateRelationLabel();

          const products = this.order.items.map((item: any) => {
              return {
                product: {quantity: 1, ...item.product},
              };
            }
          );

          this.orderDuplicateService.setBillingAddress(this.order.billingAddress);
          this.orderDuplicateService.setShippingAddress(this.order.shippingAddress);
          this.orderDuplicateService.setMarketplace(this.order.marketplace);
          this.orderDuplicateService.setPaymentType(this.order.paymentType);
          this.orderDuplicateService.setOrderId(this.order.id);
          this.orderDuplicateService.setItems(products);
          this.shortLocale = response.locale ? response.locale.split('_')[0] : this.shortLocale;
          this.currency = response.currency ? response.currency.split('_')[0] : this.currency;

          this.orderEntityCommercialCurrency = SessionHelper.getCountryByMarketplaceCode(this.order.marketplace).commercialEntity.currencyCode;
        }, () => {
          this.tryRedirect();
        }
      )
    ;
  }

  public tryRedirect(): void {
    (<OrderResource>this.resource)
      .cGet({}, { entryPoint: '/v2/orders/order-id/' + this.state.params.id }, new HttpHeaders({ Accept: 'application/json'}) )
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: any) => {
        if (response.id) {
          this.state.go(this.state.current, { id: response.id }, { reload: true });
        }
      })
    ;
  }

  public updateRefundLabel(): void {
    const tabs: ITabs[] = this.state.current.data.tabs;
    const countRefunds: string = `(${this.order.numberOfRefunds})`;

    for (const tab of tabs) {
      if (tab.state === 'order_manager.payment' && !tab.label.includes(countRefunds)) {
        tab.label = this.translate('PAGE.ORDER_MANAGER.TAB.PAYMENT').toUpperCase() + ` ${countRefunds}`;
      }
    }
  }

  public updateTabCommentLabel(): void {
    const tabs: ITabs[] = this.state.current.data.tabs;
    const countCommentSuffix: string = `(${this.order.numberOfComments})`;

    for (const tab of tabs) {
      if (tab.state === 'order_manager.comment' && !tab.label.includes(countCommentSuffix)) {
        tab.label = this.translate('PAGE.ORDER_MANAGER.TAB.COMMENT').toUpperCase() + ` ${countCommentSuffix}`;
      }
    }
  }

  public updateTabLitigationLabel(): void {
    const tabs: ITabs[] = this.state.current.data.tabs;
    const suffix: string = `(${this.order.numberOfLitigations})`;

    for (const tab of tabs) {
      if (tab.state === 'order_manager.litigation' && !tab.label.includes(suffix)) {
        tab.label = this.translate('PAGE.ORDER_MANAGER.TAB.LITIGATION').toUpperCase() + ` ${suffix}`;
      }
    }
  }

  public updateTabReverseLabel(): void {
    const tabs: ITabs[] = this.state.current.data.tabs;
    const suffix: string = `(${+(null !== this.order.reverse)})`;

    for (const tab of tabs) {
      if (tab.state === 'order_manager.reverse' && !tab.label.includes(suffix)) {
        tab.label = this.translate('PAGE.ORDER_MANAGER.TAB.REVERSE').toUpperCase() + ` ${suffix}`;
      }
    }
  }

  public updateAssociatedLabel(): void {
    const tabs: ITabs[] = this.state.current.data.tabs;
    let totalAssociated = 0;
    (<OrderResource>this.resource).getAssociated(this.state.params.id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (response: IHydraMember[]) => {
          totalAssociated = response.length;
        },
        complete: () => {
          for (const tab of tabs) {
            if (tab.state === 'order_manager.associated' && !tab.label.includes(String(totalAssociated))) {
              tab.label = this.translate('PAGE.ORDER_MANAGER.TAB.ASSOCIATED').toUpperCase() + ` (${totalAssociated})`;
            }
          }
        }
      });
  }

  public updateRelationLabel(): void {
    const tabs: ITabs[] = this.state.current.data.tabs;
    this.relationRessource.getMany({order: this.state.params.id})
      .pipe(takeUntil(this.destroyed$))
      .subscribe((data: IRelation[]) => {
        for (const tab of tabs) {
          if (tab.state === 'order_manager.relation' && !tab.label.includes(String(data.length))) {
            tab.label = this.translate('PAGE.ORDER_MANAGER.TAB.RELATION').toUpperCase() + ` (${data.length})`;
          }
        }
      });
  }

  public onIndicatorUpdateEvent(event: any) {
    this.orderManagerGeneralFormComponent.reloadIndicator();
  }

  public reloadIndicator() {
    this.indicatorComponent.fetchData();
  }

  public isWebsiteOrderType(): boolean {
    return this.order.marketplace.includes('site');
  }

  public isRepatriationOrderType(): boolean {
    return ['repatriation', 'shooting'].includes(this.order.orderType);
  }

  public reloadOrder() {
    this.ngOnInit();
  }

  private getAddressId(address: any) {
    try {
      return address['@id'].split('/').pop();
    } catch (error) {
      console.error(error);
    }
  }

  getLinks() {
    const links = [
      {
        url: `#!/customers/${this.order.customer.id}`,
        label: 'PAGE.ORDER.EDIT.LINK_TO_CUSTOMER',
      },
      {
        url: `#!/customers/${this.order.customer.id}/mails`,
        label: 'PAGE.ORDER.EDIT.LINK_TO_CUSTOMER_MAILS',
      },
      {
        url: `#!/sav/orders/new/${this.order.id}`,
        label: 'PAGE.ORDER.EDIT.CREATE_SAV_ORDER.LINK',
      },
      {
        url: `#!/orders/new`,
        label: 'PAGE.ORDER.EDIT.LINK_TO_DUPLICATE',
      },
    ];

    if (this.order.lengowUrl) {
      links.push({
        url: this.order.lengowUrl,
        label: 'PAGE.ORDER.EDIT.LINK_TO_LENGOW'
      });
    }

    if (this.order.marketplaceUrl) {
      links.push({
        url: this.order.marketplaceUrl,
        label: 'PAGE.ORDER.EDIT.LINK_TO_MARKETPLACE'
      });
    }

    return links;
  }

  public getTransactions(): any {
    this.orderManagerResource.getPaymentsRefundMinimals(this.state.params.id).subscribe((data: any) => {
      const [countGreenRefund, countOrangeRefund, countRedRefund] = this.countTransactionIndicator(data.transactions) ;

      if (countGreenRefund > 0 && countOrangeRefund === 0) {
        this.colors.push({'PAGE.ORDER_MANAGER.TAB.PAYMENT': 'btn-success'});
      }

      if (countRedRefund > 0 && countOrangeRefund === 0 && countGreenRefund === 0) {
        this.colors.push({'PAGE.ORDER_MANAGER.TAB.PAYMENT': 'btn-danger'});
      }

      if (countOrangeRefund > 0) {
        this.colors.push({'PAGE.ORDER_MANAGER.TAB.PAYMENT': 'btn-warning'});
        if (!this.showBanner) {
          this.showBanner = true;
          this.showBannerMessage = 'PAGE.ORDER_MANAGER.LIST.TEXT.CHECK_TAB';
        }
      }
    });
  }

  public countTransactionIndicator(transactions: any): Array<number> {
    let greenRefund: number = 0;
    let orangeRefund: number = 0 ;
    let redRefund: number = 0;
    transactions.forEach(function(transaction: any) {
      if (transaction.hasOwnProperty('operation')
        && transaction.operation === 'debit') {
        const transactionState = transaction.transactionIndicator.indicatorStates[0].status;
        switch (transactionState) {
          case 'green':
            greenRefund++;
            break;
          case 'orange':
            orangeRefund++;
            break;
          case 'red':
            redRefund++;
            break;
        }
      }
    });

    return [greenRefund, orangeRefund, redRefund];
  }

  public getLitigations(): any {
    let litigationFinish: number = 0;
    let litigationProcessing: number = 0;
    this.litigationResource.cGet({ 'orderId': this.state.params.id }, { returnHydraMembers: true, blocking: false })
      .takeUntil(this.destroyed$)
      .subscribe({
        next: (litigations: any[]) => {
          litigations.forEach((litigation: any) => {
            const litigationStatus = litigation.litigationStatus.split('/').pop();
            // 3 equals to Paiement reçu and 4 equals to Pas de facturation
            if (litigationStatus === '3' || litigationStatus === '4') {
              litigationFinish++;
            } else {
              litigationProcessing++;
            }
          });
        },
        complete: () => {
          if (litigationProcessing > 0) {
            this.colors.push({'PAGE.ORDER_MANAGER.TAB.LITIGATION': 'btn-warning'});
            if (!this.showBanner) {
              this.showBanner = true;
              this.showBannerMessage = 'PAGE.ORDER_MANAGER.LIST.TEXT.CHECK_TAB';
            }
          }

          if (litigationFinish > 0 && litigationProcessing === 0) {
            this.colors.push({'PAGE.ORDER_MANAGER.TAB.LITIGATION': 'btn-success'});
          }
        }
      });
  }

  public getAssociated(): any {
    let greenAssociated: number = 0;
    let orangeAssociated: number = 0 ;
    let redAssociated: number = 0;
    (<OrderResource>this.resource).getAssociated(this.state.params.id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (associated: any[]) => {
          associated.forEach((order: any) => {
            if (order.orderType === 'sav') {
              order.items.forEach((item: any ) => {
                switch (item.orderManagerStatus) {
                  case 'new':
                  case 'accepted':
                  case 'preparing':
                    orangeAssociated++;
                    break;
                  case 'canceled':
                  case 'refused':
                    redAssociated++;
                    break;
                  case 'shipped':
                  case 'delivered':
                    greenAssociated++;
                    break;
                }
              });
            }
          });
        },
        complete: () => {
          if (greenAssociated > 0 && orangeAssociated === 0) {
            this.colors.push({'PAGE.ORDER_MANAGER.TAB.ASSOCIATED': 'btn-success'});
          }

          if (redAssociated > 0 && greenAssociated === 0 && orangeAssociated === 0) {
            this.colors.push({'PAGE.ORDER_MANAGER.TAB.ASSOCIATED': 'btn-danger'});
          }

          if (orangeAssociated > 0) {
            this.colors.push({'PAGE.ORDER_MANAGER.TAB.ASSOCIATED': 'btn-warning'});
            if (!this.showBanner) {
              this.showBanner = true;
              this.showBannerMessage = 'PAGE.ORDER_MANAGER.LIST.TEXT.CHECK_TAB';
            }
          }
        }
      });
  }

  public getRelations(): any {
    let closedTicket: number = 0;
    let openedTicket: number = 0;
    this.relationRessource.getMany({order: this.state.params.id}, {}).subscribe({
      next: (tickets: IRelation[]) => {
        tickets.forEach((ticket: any) => {
          if (ticket.status === 'closed') {
            closedTicket++;
          } else if (ticket.status === 'opened') {
            openedTicket++;
          }
        });
      },
      complete: () => {
        if (openedTicket) {
          this.colors.push({'PAGE.ORDER_MANAGER.TAB.RELATION': 'btn-warning'});
          if (!this.showBanner) {
            this.showBanner = true;
            this.showBannerMessage = 'PAGE.ORDER_MANAGER.LIST.TEXT.CHECK_TAB';
          }
        }

        if (closedTicket && !openedTicket) {
          this.colors.push({'PAGE.ORDER_MANAGER.TAB.RELATION': 'btn-success'});
        }
      }
    });
  }
}
